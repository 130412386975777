import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"

import * as articleTemplateStyles from "../styles/templates/article-template.module.scss"
import Head from "../components/head"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
      }
      html
    }
  }
`

const Blog = ({ data }) => {
  console.log(data)
  const markdown = data.markdownRemark
  return (
    <Layout>
      <Head title={markdown.frontmatter.title} />
      <h1>{markdown.frontmatter.title}</h1>
      <p>{markdown.frontmatter.date}</p>
      <div
        className={articleTemplateStyles.contentContainer}
        dangerouslySetInnerHTML={{ __html: markdown.html }}
      ></div>
    </Layout>
  )
}

export default Blog
